@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-800;
}

h1, h2, h3, h4, h5, h6 {
  @apply text-gray-800;
}

a {
  @apply text-pink-500 hover:text-pink-600;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
